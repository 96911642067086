import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./OurCompany.css";

const OurCompany = () => {
  const navigate = useNavigate();

  const onTextGroupWithCTAThinklabGClick = useCallback(() => {
    navigate("/company");
  }, [navigate]);

  const onOverlatTextLinkClick = useCallback(() => {
    // Please sync "Company" to the project
  }, []);

  const onOverlatTextLink1Click = useCallback(() => {
    navigate("/team");
  }, [navigate]);

  const onOverlatTextLink2Click = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <div className="our-company-overlay">
      <div
        className="text-group-with-cta-thinklab-g"
        onClick={onTextGroupWithCTAThinklabGClick}
      >
        <div className="text-group-thinklab">
          <div className="heading-block-text">
            <b className="heading-block-text1">OUR COMPANY</b>
          </div>
          <div className="paragraph-block-text">
            <div className="lorem-ipsum-dolor">
              <a className="leftSideText"> Thinklab Group – where innovation meets excellence. Discover our
              journey and shape the future with us.</a>
             
            </div>
          </div>
        </div>
        <div className="secondary-button">
          <b className="button">Explore Our Story</b>
        </div>
      </div>
      <div className="our-company-links-container-parent">
        <div className="our-company-links-container">
          <div className="heading-block-text">
            <b className="text-link"><a>Our Company</a></b>
          </div>
          <div className="overlat-text-link">
            <b className="text-link">Our Company</b>
          </div>
          <div className="secondary-links-group">
            <div
              className="overlat-text-link1"
              onClick={onOverlatTextLinkClick}
            >
              <div className="text-link1"><a>About Us</a></div>
            </div>
            <div
              className="overlat-text-link1"
              onClick={onOverlatTextLink1Click}
            >
              <div className="text-link1"><a>Our Team</a></div>
            </div>
            <div className="heading-block-text">
              <div className="text-link1"><a>Our Partners</a></div>
            </div>
            <button
              className="overlat-text-link4"
              onClick={onOverlatTextLink2Click}
            >
              <div className="text-link4">Contact Us</div>
            </button>
          </div>
        </div>
        <div className="our-company-links-container">
          <div className="heading-block-text">
            <b className="text-link">Our Culture</b>
          </div>
          <div className="overlat-text-link">
            <b className="text-link">Our Culture</b>
          </div>
          <div className="secondary-links-group">
            <div className="heading-block-text">
              <div className="text-link1">Our ESG Strategy</div>
            </div>
            <div className="heading-block-text">
              <div className="text-link1">{`Community & Citizenship`}</div>
            </div>
            <div className="heading-block-text">
              <div className="text-link1">{`Diversity, Equity & Inclusion`}</div>
            </div>
            <div className="heading-block-text">
              <div className="text-link1">{`Environmental Sustainability `}</div>
            </div>
            <div className="heading-block-text">
              <div className="text-link1">Innovation</div>
            </div>
            <div className="heading-block-text">
              <div className="text-link1">{`Safety & Wellness`}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCompany;
