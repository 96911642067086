import { useState, useCallback } from "react";
import TabletmobileOverlayMenu1 from "../components/TabletmobileOverlayMenu.js";
import PortalPopup from "../components/PortalPopup.js";
import { useNavigate } from "react-router-dom";
import "./Company.css";
import NavBar from "../components/NavBar.js";

const Company = () => {
  const [isTabletmobileOverlayMenuOpen, setTabletmobileOverlayMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const onNavBarLogoWrapperClick = useCallback(() => {
    // Please sync "Home" to the project
  }, []);

  const onNavitem3Click = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onNavitem4ContainerClick = useCallback(() => {
    navigate("/news");
  }, [navigate]);

  const onContactnavContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const openTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(true);
  }, []);

  const closeTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(false);
  }, []);

  const onViewallteamsbuttonContainerClick = useCallback(() => {
    navigate("/team");
  }, [navigate]);

  return (
    <>
      <div className="company">
      <NavBar/>
        <div className="taglinebody5">
          <div className="taglinetextgroupthinklab5">
            <div className="leaderhipteamourcompanyheading">
              <b className="taglineheadingblocktext5">Our Company</b>
            </div>
            <div className="leaderhipteamourcompanycardpar">
              <div className="taglineparagraphblock-text">
                Discover the Story Behind Thinklab Group's Innovation and
                Commitment to Building a Better Tomorrow.
              </div>
            </div>
          </div>
        </div>
        <div className="our-company-sectionbigger">
          <div className="heading-block-text67">
            <b className="our-company1">Our Company</b>
          </div>
          <div className="ourcompanyabout-us">
            <div className="ourcompanyaboutustectgroup">
              <div className="ourcompanyaboutustextgrouptext">
                <div className="leaderhipteamourcompanyheading">
                  <b className="our-history">Our History</b>
                </div>
                <div className="leaderhipteamourcompanyparagra">
                  <div className="taglineparagraphblock-text">
                    Thinklab Group has a rich history dating back to the
                    mid-2000s. Comprising Thinklab Development Company Limited,
                    Thinklab Energy Limited, Thinklab International Consulting
                    Limited, and Thinklab Digital Technology Limited, it has
                    grown into a reputable provider known for delivering
                    reliable, innovative, and efficient solutions for a wide
                    range of construction projects. With expertise in City
                    Development, building construction, industrial facilities,
                    civil structures, roads, and infrastructure, Thinklab Group
                    consistently achieves exceptional results that meet industry
                    standards. Through diversification, specialization, and a
                    steadfast commitment to excellence, Thinklab Group continues
                    to pave the way for sustainable and impactful developments,
                    shaping a better future for communities and stakeholders
                    alike.
                  </div>
                </div>
              </div>
            </div>
            <img
              className="ourcompanyaboutusimg-icon"
              alt=""
              src="/ourcompanyaboutusimg@2x.png"
            />
          </div>
          <div className="ourcompanyabout-us">
            <img
              className="ourcompanyaboutusimg-icon1"
              alt=""
              src="/ourcompanyaboutusimg1@2x.png"
            />
            <div className="ourcompanyaboutustectgroup1">
              <div className="ourcompanyaboutustextgrouptext">
                <div className="leaderhipteamourcompanyheading">
                  <b className="our-history">Our Vision</b>
                </div>
                <div className="leaderhipteamourcompanyparagra">
                  <div className="ourpartnerstitletext">
                    To become a financially sustainable, operationally
                    excellent, and innovative development alliance, recognized
                    for its strong brand and robust corporate governance.
                  </div>
                </div>
              </div>
              <div className="primary-button5">
                <b className="button20">Button</b>
              </div>
            </div>
          </div>
          <div className="ourcompanyabout-us">
            <div className="ourcompanyaboutustectgroup1">
              <div className="ourcompanyaboutustextgrouptext">
                <div className="leaderhipteamourcompanyheading">
                  <b className="our-history">Our Mission</b>
                </div>
                <div className="leaderhipteamourcompanyparagra">
                  <div className="ourpartnerstitletext">
                    To diversify our business, achieve operational excellence,
                    ensure financial sustainability, strengthen our brand, and
                    enhance corporate governance while establishing clear
                    divisions of responsibilities within the company’s
                    leadership structure
                  </div>
                </div>
              </div>
              <div className="primary-button5">
                <b className="button20">Button</b>
              </div>
            </div>
            <img
              className="ourcompanyaboutusimg-icon2"
              alt=""
              src="/ourcompanyaboutusimg2@2x.png"
            />
          </div>
        </div>
        <div className="ourculturesection1">
          <div className="ourculturetextgroup1">
            <div className="heading-block-text73">
              <b className="heading-block-text74">Our Culture</b>
            </div>
            <div className="paragraph-block-text27">
              <div className="our-culture-is1">
                Our culture is a vibrant tapestry woven from diverse threads:
                the dedication of our people, the relentless pursuit of
                innovation, and the unwavering commitment to making a positive
                impact. Together, we build not just structures, but legacies.
              </div>
            </div>
          </div>
          <div className="ourculturemainslider1">
            <div className="our-culture-navigtion-link1">
              <div className="our-culture-linkselected1">
                <b className="ourculturelinkselectedtext1">ESG Strategy</b>
              </div>
              <div className="our-culture-link5">
                <div className="taglineparagraphblock-text">Community</div>
              </div>
              <div className="our-culture-link5">
                <div className="taglineparagraphblock-text">{`DE&I`}</div>
              </div>
              <div className="our-culture-link5">
                <div className="taglineparagraphblock-text">Environmental</div>
              </div>
              <div className="our-culture-link5">
                <div className="taglineparagraphblock-text">Innovation</div>
              </div>
              <div className="our-culture-link5">
                <div className="taglineparagraphblock-text">{`Safety & Wellness`}</div>
              </div>
            </div>
            <div className="our-culture-media-sliderbody21">
              <div className="ourcultureslidertextgroup1">
                <div className="headingblockourcultureslider1">
                  <b className="headingblockourculturesliderte1">{`Environmental, Social, & Governance (ESG) Strategy`}</b>
                </div>
                <div className="textblockourcultureslider1">
                  We weave sustainability and ethical practices into everything
                  we do, minimizing our environmental impact and building a
                  better future
                </div>
                <div className="ourculturebutton1">
                  <b className="ourculturebuttontext1">Learn More</b>
                </div>
              </div>
              <img
                className="ourcultureimg-icon1"
                alt=""
                src="/ourcultureimg1@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="leaderhipteamourcompany">
          <div className="leaderhipteamourcompanygroup">
            <div className="leaderhipteamourcompanyheading">
              <b className="heading-block-text74">Our Leadership Team</b>
            </div>
            <div className="leaderhipteamourcompanyparagra">
              <div className="lorem-ipsum-dolor28">
                We’re a dynamic group of individuals who are passionate about
                what we do and dedicated to delivering the best results for our
                clients.
              </div>
            </div>
          </div>
          <div className="leaderhipteamourcompanybody">
            <div className="leaderhipteamourcompanycard">
              <img
                className="leaderhipteamourcompanycardimg-icon"
                alt=""
                src="/leaderhipteamourcompanycardimg@2x.png"
              />
              <div className="leaderhipteamourcompanycardtag">
                <div className="leaderhipteamcardtextgroup">
                  <div className="leaderhipteamourcompanyheading">
                    <b className="leaderhipteamourcompanycardtex">
                      Dr. Sa’id Kori
                    </b>
                  </div>
                  <div className="leaderhipteamourcompanycardpar">
                    <div className="ourpartnerstitletext">
                      Chairman / Chief Executive Officer (CEO)
                    </div>
                  </div>
                </div>
                <div className="leaderhipteamourcompanycardsoc">
                  <div className="leaderhipteamourcompanycardsoc1">
                    <img
                      className="twitter-logo-icon20"
                      alt=""
                      src="/twitter-logo2.svg"
                    />
                    <img
                      className="twitter-logo-icon20"
                      alt=""
                      src="/instagram-logosvg2.svg"
                    />
                    <img
                      className="twitter-logo-icon20"
                      alt=""
                      src="/facebook-symbolsvg2.svg"
                    />
                  </div>
                  <div className="dropdown-icon44">
                    <img
                      className="stat-minus-1-icon42"
                      alt=""
                      src="/stat-minus-1.svg"
                    />
                  </div>
                </div>
                <div className="leaderhipteamourcompanycardpar2">
                  <div className="ourpartnerstitletext">
                    Dr. Sa’id is a versatile professional with a background in
                    architecture and a passion for development finance. With
                    experience as both a licensed researcher and a project
                    manager, He brings a unique blend of skills to Thinklab
                    Group. His expertise lies in strategic planning, financial
                    analysis, and project execution, making him a driving force
                    behind our innovative and sustainable development projects.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="leaderhipteamourcompanybody1">
            <div className="leaderhipteamourcompanycard1">
              <img
                className="leaderhipteamourcompanycardimg-icon1"
                alt=""
                src="/leaderhipteamourcompanycardimg1@2x.png"
              />
              <div className="leaderhipteamourcompanycardtag">
                <div className="leaderhipteamcardtextgroup">
                  <div className="leaderhipteamourcompanyheading">
                    <b className="leaderhipteamourcompanycardtex1">
                      Dr Babagana Adam
                    </b>
                  </div>
                  <div className="leaderhipteamourcompanycardpar">
                    <div className="leaderhipteamourcompanycardpar5">
                      Group Managing Director (GMD)
                    </div>
                  </div>
                </div>
                <div className="leaderhipteamourcompanycardsoc">
                  <div className="leaderhipteamourcompanycardsoc1">
                    <img
                      className="twitter-logo-icon20"
                      alt=""
                      src="/twitter-logo2.svg"
                    />
                    <img
                      className="twitter-logo-icon20"
                      alt=""
                      src="/instagram-logosvg2.svg"
                    />
                    <img
                      className="twitter-logo-icon20"
                      alt=""
                      src="/facebook-symbolsvg2.svg"
                    />
                  </div>
                  <div className="dropdown-icon44">
                    <img
                      className="stat-minus-1-icon42"
                      alt=""
                      src="/stat-minus-1.svg"
                    />
                  </div>
                </div>
                <div className="leaderhipteamourcompanycardpar2">
                  <div className="ourpartnerstitletext">
                    Dr. Babagana is an accomplished professional with a strong
                    background in collaborating with government,
                    non-governmental organizations (NGOs), and the higher
                    education sector. He holds a PhD in Epidemiology from the
                    University of Salford Manchester UK, showcasing his
                    expertise in research and critical analysis. His leadership
                    skills and strategic vision have been pivotal in driving
                    growth and fostering partnerships at Thinklab Group.
                  </div>
                </div>
              </div>
            </div>
            <div className="leaderhipteamourcompanycard1">
              <img
                className="leaderhipteamourcompanycardimg-icon2"
                alt=""
                src="/leaderhipteamourcompanycardimg2@2x.png"
              />
              <div className="leaderhipteamourcompanycardtag">
                <div className="leaderhipteamcardtextgroup">
                  <div className="leaderhipteamourcompanyheading">
                    <b className="leaderhipteamourcompanycardtex">
                      Babagana Kolomi
                    </b>
                  </div>
                  <div className="leaderhipteamourcompanycardpar">
                    <div className="ourpartnerstitletext">Chief Of Staff</div>
                  </div>
                </div>
                <div className="leaderhipteamourcompanycardsoc">
                  <div className="leaderhipteamourcompanycardsoc1">
                    <img
                      className="twitter-logo-icon20"
                      alt=""
                      src="/twitter-logo2.svg"
                    />
                    <img
                      className="twitter-logo-icon20"
                      alt=""
                      src="/instagram-logosvg2.svg"
                    />
                    <img
                      className="twitter-logo-icon20"
                      alt=""
                      src="/facebook-symbolsvg2.svg"
                    />
                  </div>
                  <div className="dropdown-icon44">
                    <img
                      className="stat-minus-1-icon42"
                      alt=""
                      src="/stat-minus-1.svg"
                    />
                  </div>
                </div>
                <div className="leaderhipteamourcompanycardpar2">
                  <div className="ourpartnerstitletext">
                    <p className="adolf-diana-our">
                      Lorem ipsum dolor sit amet consectetur. Nunc facilisi
                      turpis sit fringilla. Tempor id donec dolor malesuada
                      pretium convallis. Elementum amet nascetur facilisi id eu.
                      Tincidunt nullam odio turpis habitant urna curabitur
                      malesuada. Suscipit porttitor dui a eu diam. In porta
                      feugiat velit ut tincidunt pellentesque leo turpis erat.
                      Nisl pellentesque.
                    </p>
                    <p className="adolf-diana-our">&nbsp;</p>
                    <p className="adolf-diana-our">
                      Elementum amet nascetur facilisi id eu. Tincidunt nullam
                      odio turpis habitant urna curabitur malesuada. Suscipit
                      porttitor dui a eu diam. In porta feugiat velit ut
                      tincidunt pellentesque leo turpis erat. Nisl pellentesque.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="leaderhipteamourcompanycard1">
              <img
                className="leaderhipteamourcompanycardimg-icon2"
                alt=""
                src="/leaderhipteamourcompanycardimg21@2x.png"
              />
              <div className="leaderhipteamourcompanycardtag">
                <div className="leaderhipteamcardtextgroup">
                  <div className="leaderhipteamourcompanyheading">
                    <b className="leaderhipteamourcompanycardtex">
                      Veronica Braimoh
                    </b>
                  </div>
                  <div className="leaderhipteamourcompanycardpar">
                    <div className="ourpartnerstitletext">
                      Group Head of Cooperate Service (GHCS)
                    </div>
                  </div>
                </div>
                <div className="leaderhipteamourcompanycardsoc">
                  <div className="leaderhipteamourcompanycardsoc1">
                    <img
                      className="twitter-logo-icon20"
                      alt=""
                      src="/twitter-logo2.svg"
                    />
                    <img
                      className="twitter-logo-icon20"
                      alt=""
                      src="/instagram-logosvg2.svg"
                    />
                    <img
                      className="twitter-logo-icon20"
                      alt=""
                      src="/facebook-symbolsvg2.svg"
                    />
                  </div>
                  <div className="dropdown-icon44">
                    <img
                      className="stat-minus-1-icon42"
                      alt=""
                      src="/stat-minus-1.svg"
                    />
                  </div>
                </div>
                <div className="leaderhipteamourcompanycardpar2">
                  <div className="ourpartnerstitletext">
                    As the Head of Corporate Services at Thinklab Group,
                    Veronica is a seasoned professional with a wealth of
                    experience in managing diverse functions within the
                    organization.  She brings a strategic mindset and a proven
                    track record of optimizing operational efficiency and
                    driving organizational success. Her leadership and expertise
                    play a crucial role in ensuring smooth and effective
                    corporate operations, and supporting the overall mission and
                    goals of Thinklab Group.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ourteamsframe">
            <div
              className="viewallteamsbutton"
              onClick={onViewallteamsbuttonContainerClick}
            >
              <b className="button20">View All</b>
            </div>
          </div>
        </div>
        <div className="ourpartnerssection">
          <div className="ourpartnertextgroup-thinklab">
            <div className="ourpartnersheader">
              <b className="ourpartnersheadertext">
                Allied Entities/Our Partners
              </b>
            </div>
            <div className="leaderhipteamourcompanyparagra">
              <div className="ourpartnerstitletext">
                Strong Together: Our Network of Expertise
              </div>
            </div>
          </div>
          <div className="our-partners-group-logo-contai">
            <div className="our-partner-logo-container">
              <img
                className="partner-logo-icon"
                alt=""
                src="/partner-logo@2x.png"
              />
              <div className="text-group-thinklab9">
                <div className="leaderhipteamourcompanyheading">
                  <b className="leaderhipteamourcompanycardtex">
                    Partner Title
                  </b>
                </div>
                <div className="paragraph-block-text31">
                  <div className="lorem-ipsum-dolor-container16">
                    <p className="adolf-diana-our">
                      Adolf Diana our no.1 partner.
                    </p>
                    <p className="adolf-diana-our">{`Lorem ipsum dolor sit amet consectetur. Consectetur nibh convallis sed odio viverra. Odio id aliquam condimentum bibendum. `}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-partner-logo-container">
              <img
                className="partner-logo-icon"
                alt=""
                src="/partner-logo1@2x.png"
              />
              <div className="text-group-thinklab9">
                <div className="leaderhipteamourcompanyheading">
                  <b className="leaderhipteamourcompanycardtex">
                    Partner Title
                  </b>
                </div>
                <div className="paragraph-block-text31">
                  <div className="lorem-ipsum-dolor-container16">
                    <p className="adolf-diana-our">
                      Adolf Diana our no.1 partner.
                    </p>
                    <p className="adolf-diana-our">{`Lorem ipsum dolor sit amet consectetur. Consectetur nibh convallis sed odio viverra. Odio id aliquam condimentum bibendum. `}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-partner-logo-container">
              <img
                className="partner-logo-icon"
                alt=""
                src="/partner-logo2@2x.png"
              />
              <div className="text-group-thinklab9">
                <div className="leaderhipteamourcompanyheading">
                  <b className="leaderhipteamourcompanycardtex">
                    Partner Title
                  </b>
                </div>
                <div className="paragraph-block-text31">
                  <div className="lorem-ipsum-dolor-container16">
                    <p className="adolf-diana-our">
                      Adolf Diana our no.1 partner.
                    </p>
                    <p className="adolf-diana-our">{`Lorem ipsum dolor sit amet consectetur. Consectetur nibh convallis sed odio viverra. Odio id aliquam condimentum bibendum. `}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-partner-logo-container">
              <img
                className="partner-logo-icon"
                alt=""
                src="/partner-logo3@2x.png"
              />
              <div className="text-group-thinklab9">
                <div className="leaderhipteamourcompanyheading">
                  <b className="leaderhipteamourcompanycardtex">
                    Partner Title
                  </b>
                </div>
                <div className="paragraph-block-text31">
                  <div className="lorem-ipsum-dolor-container16">
                    <p className="adolf-diana-our">
                      Adolf Diana our no.1 partner.
                    </p>
                    <p className="adolf-diana-our">{`Lorem ipsum dolor sit amet consectetur. Consectetur nibh convallis sed odio viverra. Odio id aliquam condimentum bibendum. `}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-partner-logo-container">
              <img
                className="partner-logo-icon"
                alt=""
                src="/partner-logo4@2x.png"
              />
              <div className="text-group-thinklab9">
                <div className="leaderhipteamourcompanyheading">
                  <b className="leaderhipteamourcompanycardtex">
                    Partner Title
                  </b>
                </div>
                <div className="paragraph-block-text31">
                  <div className="lorem-ipsum-dolor-container16">
                    <p className="adolf-diana-our">
                      Adolf Diana our no.1 partner.
                    </p>
                    <p className="adolf-diana-our">{`Lorem ipsum dolor sit amet consectetur. Consectetur nibh convallis sed odio viverra. Odio id aliquam condimentum bibendum. `}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-partner-logo-container5">
              <img
                className="partner-logo-icon"
                alt=""
                src="/partner-logo5@2x.png"
              />
              <div className="text-group-thinklab9">
                <div className="leaderhipteamourcompanyheading">
                  <b className="leaderhipteamourcompanycardtex">
                    Partner Title
                  </b>
                </div>
                <div className="paragraph-block-text31">
                  <div className="lorem-ipsum-dolor-container16">
                    <p className="adolf-diana-our">
                      Adolf Diana our no.1 partner.
                    </p>
                    <p className="adolf-diana-our">{`Lorem ipsum dolor sit amet consectetur. Consectetur nibh convallis sed odio viverra. Odio id aliquam condimentum bibendum. `}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us-section-thinklab3">
          <div className="text-group-thinklab15">
            <div className="heading-block-text88">
              <b className="heading-block-text89">Contact Us</b>
            </div>
            <div className="leaderhipteamourcompanyparagra">
              <div className="ourpartnerstitletext">
                We appreciate your interest in Thinklab Group. Whether you're
                seeking assistance or have a general inquiry, we're eager to
                engage. Send us a message, and you can expect a prompt response.
              </div>
            </div>
          </div>
          <div className="contact-us-lower-container3">
            <div className="contact-form-thinklab3">
              <div className="upper-contact-form-section3">
                <div className="heading-block-text90">
                  <b className="heading-block-text91">Contact Form</b>
                </div>
                <div className="input-groups-container-thinkla3">
                  <div className="leaderhipteamcardtextgroup">
                    <div className="leaderhipteamourcompanyheading">
                      <div className="form-label-text19">Name</div>
                    </div>
                    <input
                      className="input-field-single7"
                      placeholder="Enter Your Full Name"
                      type="text"
                    />
                  </div>
                  <div className="leaderhipteamcardtextgroup">
                    <div className="leaderhipteamourcompanyheading">
                      <div className="form-label-text19">Email</div>
                    </div>
                    <input
                      className="input-field-single7"
                      placeholder="Enter Your Email Address"
                      type="text"
                    />
                  </div>
                  <div className="leaderhipteamcardtextgroup">
                    <div className="leaderhipteamourcompanyheading">
                      <div className="form-label-text19">Message</div>
                    </div>
                    <textarea
                      className="input-field-multi-line3"
                      placeholder="Enter Your Message"
                    />
                  </div>
                </div>
              </div>
              <button className="primary-button7">
                <b className="button23">Send Message</b>
              </button>
            </div>
            <div className="contact-address-card3">
              <div className="heading-block-text90">
                <b className="heading-block-text91">Contact Address</b>
              </div>
              <div className="contat-info-buttom-section3">
                <div className="address-info-container3">
                  <img
                    className="address-icon3"
                    alt=""
                    src="/address-icon.svg"
                  />
                  <div className="secondary-text-link45">
                    <div className="ourpartnerstitletext">
                      87 Samuel Ladoke Akintola Boulevard, Garki 2, Abuja
                    </div>
                  </div>
                </div>
                <div className="call-info-container3">
                  <img className="address-icon3" alt="" src="/call-icon.svg" />
                  <div className="secondary-text-link45">
                    <div className="ourpartnerstitletext">+234 9084359943</div>
                  </div>
                </div>
                <div className="address-info-container3">
                  <img className="mail-icon3" alt="" src="/mail-icon.svg" />
                  <div className="secondary-text-link45">
                    <div className="ourpartnerstitletext">
                      Project@thinklabgroup.com.ng
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-section-thinklab6">
          <div className="footer-upper-container6">
            <div className="logotaglinesocial-links-cont6">
              <div className="footer-logo-and-tagline-contai6">
                <img
                  className="thinklab-logo-icon14"
                  alt=""
                  src="/thinklab-logo@2x.png"
                />
                <div className="paragraph-block-text38">
                  <div className="ourpartnerstitletext">
                    <p className="adolf-diana-our">
                      Crafting Excellence in every blueprint.
                    </p>
                    <p className="adolf-diana-our">
                      Trust us to redefine standards and deliver unmatched
                      efficiency across diverse domains.
                    </p>
                  </div>
                </div>
              </div>
              <div className="leaderhipteamourcompanycardsoc1">
                <img
                  className="twitter-logo-icon20"
                  alt=""
                  src="/twitter-logo1.svg"
                />
                <img
                  className="twitter-logo-icon20"
                  alt=""
                  src="/instagram-logosvg1.svg"
                />
                <img
                  className="twitter-logo-icon20"
                  alt=""
                  src="/facebook-symbolsvg1.svg"
                />
              </div>
            </div>
            <div className="footer-navigation-link6">
              <div className="leaderhipteamourcompanyheading">
                <div className="form-label-text19">Our Company</div>
              </div>
              <div className="leaderhipteamourcompanyheading">
                <div className="form-label-text19">Our Services</div>
              </div>
              <div className="leaderhipteamourcompanyheading">
                <div className="form-label-text19">Our Projects</div>
              </div>
              <div className="leaderhipteamourcompanyheading">
                <div className="form-label-text19">{`News & Insights`}</div>
              </div>
              <div className="leaderhipteamourcompanyheading">
                <div className="form-label-text19">Careers</div>
              </div>
              <div className="leaderhipteamourcompanyheading">
                <div className="form-label-text19">Contact Us</div>
              </div>
            </div>
          </div>
          <div className="all-right-reserved6">
            <div className="copyright-parent4">
              <img
                className="twitter-logo-icon20"
                alt=""
                src="/copyright.svg"
              />
              <div className="leaderhipteamourcompanyheading">
                <div className="div16">2024</div>
              </div>
            </div>
            <div className="div16">{`Thinklab Group. All Rights Reserved. `}</div>
          </div>
        </div>
      </div>
     {/*  {isTabletmobileOverlayMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTabletmobileOverlayMenu}
        >
          <TabletmobileOverlayMenu1 onClose={closeTabletmobileOverlayMenu} />
        </PortalPopup>
      )} */}
    </>
  );
};

export default Company;
