import React from 'react'
import { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OurCompany from './OurCompany.js';
import OurServices from './OurService.js';
import PortalPopup from './PortalPopup.js';
import TabletmobileOverlayMenu1 from './TabletmobileOverlayMenu.js';
import TabletMenu from "../components/TabletMenu.js";

import './NavBar.css'
const NavBar = () => {

    const [isTabletmobileOverlayMenuOpen, setTabletmobileOverlayMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const onNavBarLogoWrapperClick = useCallback(() => {
    // Please sync "Home" to the project
    navigate("/");
  }, []);

  const onNavitem3Click = useCallback(() => {
    navigate("/projects");
  }, [navigate]);



  const onNavitem4ContainerClick = useCallback(() => {
    navigate("/news");
  }, [navigate]);

  const onContactnavContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const openTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(true);
  }, []);

  const closeTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(false);
  }, []);

  const onNewsCardHomeButtonContainerClick = useCallback(() => {
    // Please sync "Single News " to the project
  }, []);

  const onNewsCardHomeButtonContainer2Click = useCallback(() => {
    // Please sync "Single News " to the project
  }, []);

  const onNewsCardHomeButtonContainer3Click = useCallback(() => {
    // Please sync "Single News " to the project
  }, []);

  const onNewsCardHomeButtonContainer4Click = useCallback(() => {
    // Please sync "Single News " to the project
  }, []);

  const onNewsCardHomeButtonContainer5Click = useCallback(() => {
    // Please sync "Single News " to the project
  }, []);

  const onAboutUsHomeButtonClick = useCallback(() => {
    // Please sync "Company" to the project
    navigate("/company");
  }, []);

  const onOurProjectSectionButtonClick = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onAbousUsClick= useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const openTabletMenuPopup = useCallback(() => {
    setTabletMenuPopupOpen(true);
  }, []);

  const closeTabletMenuPopup = useCallback(() => {
    setTabletMenuPopupOpen(false);
  }, []);

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  
  const [isTabletMenuPopupOpen, setTabletMenuPopupOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!menuRef.current?.contains(event.target)) {
        setShowMenu(false);
      }
    };
    
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuRef]);
  


  const [showServiceMenu, setShowServiceMenu] = useState(false);
  const serviceMenuRef = useRef(null);

  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!serviceMenuRef.current?.contains(event.target)) {
        setShowServiceMenu(false);
      }
    };
    
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [serviceMenuRef]);

  const [activeItem, setActiveItem] = useState(null); // Track which item is active

  const handleClick = (item) => {
    setActiveItem(item);  // Set the clicked item as active
  };



  return (
    <div className="navbar">
          <div className="navbar-inner-wrapper">
            <div
              className="navbar-logo-wrapper"
              onClick={onNavBarLogoWrapperClick}
            >
              <img
                className="thinklab-logo-icon"
                alt=""
                src="/thinklab-logo@2x.png"
              />
            </div>
            <div className="nav-list">
              <div className="navitem2"  >
                <div className="naviteminner2" 
                onMouseEnter={() => setShowMenu(true)}
              
              
                >
                  <div className={`innerNavItems ${activeItem === 'company' ? 'active' : ''}`}
        onClick={() => handleClick('company')}
><a className='navbarbuttons'>OUR COMPANY </a></div>
                  <div className="dropdown-icon">
                    <img
                      className="stat-minus-1-icon"
                      alt=""
                      src="/stat-minus-1.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="navitem21">
                <div className="naviteminner2"  onMouseEnter={() => setShowServiceMenu(true)}>
                  <div  className={`innerNavItems ${activeItem === 'service' ? 'active' : ''}`}
        onClick={() => handleClick('service')}><a className='navbarbuttons'>OUR SERVICE</a></div>
                  <div className="dropdown-icon">
                    <img
                      className="stat-minus-1-icon"
                      alt=""
                      src="/stat-minus-1.svg"
                    />
                  </div>
                </div>
              </div>
              <button className="navitem3" onClick={onNavitem3Click}>
                <div className={`innerNavItems ${activeItem === 'project' ? 'active' : ''}`}
        onClick={() => handleClick('project')}><a className='navbarbuttons'>OUR PROJECT</a></div>
                <div className="dropdown-icon2">
                  <img
                    className="stat-minus-1-icon"
                    alt=""
                    src="/stat-minus-1.svg"
                  />
                </div>
              </button>
              <div className="navitem4" onClick={onNavitem4ContainerClick}>
                <div  className={`innerNavItems ${activeItem === 'news' ? 'active' : ''}`}
        onClick={() => handleClick('news')}><a className='navbarbuttons'>NEWS & INSIGHTS</a></div>
              </div>
              <div className="navitem5">
                <div className={`innerNavItems ${activeItem === 'careers' ? 'active' : ''}`}
        onClick={() => handleClick('careers')} ><a className='navbarbuttons'>CAREERS</a></div>
                <div className="dropdown-icon2">
                  <img
                    className="stat-minus-1-icon"
                    alt=""
                    src="/stat-minus-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="navcontact">
              <div className="contactnav" onClick={onContactnavContainerClick}>
                <b className="contact-us">Contact Us</b>
              </div>
              <img
                className="navmenuoutline-icon"
                alt=""
                src="/navmenuoutline.svg"
                //onClick={openTabletmobileOverlayMenu}
                onClick={openTabletMenuPopup}
              />
            </div>
          </div>
          {showMenu && (
        <OurCompany ref={menuRef} />
      )}

{showServiceMenu && (
        <OurServices ref={serviceMenuRef} />
      )}


{isTabletmobileOverlayMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTabletmobileOverlayMenu}
        >
          <TabletmobileOverlayMenu1 onClose={closeTabletmobileOverlayMenu} />
        </PortalPopup>
      )}

{isTabletMenuPopupOpen && (
        // <PortalPopup
        //   overlayColor="rgba(113, 113, 113, 0.3)"
        //   placement="Centered"
        //   onOutsideClick={closeTabletMenuPopup}
        // >
          <TabletMenu onClose={closeTabletMenuPopup} />
        // </PortalPopup>
      )}

         
        </div>
  )
}

export default NavBar