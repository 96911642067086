import { useState, useCallback, useEffect, useRef } from "react";

import PortalPopup from "../components/PortalPopup.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Home.css";
import NavBar from "../components/NavBar.js";
import OurCompany from "../components/OurCompany.js";
import newsData from "../data/news.js";

import axios from "axios";
import { BASE_Server_URL } from "../requestMethods.js";

const Home = () => {
  const [isTabletmobileOverlayMenuOpen, setTabletmobileOverlayMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const onNavBarLogoWrapperClick = useCallback(() => {
    // Please sync "Home" to the project
  }, []);

  const onNavitem3Click = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onNavitem4ContainerClick = useCallback(() => {
    navigate("/news");
  }, [navigate]);

  const onContactnavContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const openTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(true);
  }, []);

  const closeTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(false);
  }, []);

  const onNewsCardHomeButtonContainerClick = useCallback(() => {
    // Please sync "Single News " to the project
  }, []);

  const onNewsCardHomeButtonContainer2Click = useCallback(() => {
    // Please sync "Single News " to the project
  }, []);

  const onNewsCardHomeButtonContainer3Click = useCallback(() => {
    // Please sync "Single News " to the project
  }, []);

  const onNewsCardHomeButtonContainer4Click = useCallback(() => {
    // Please sync "Single News " to the project
  }, []);

  const onNewsCardHomeButtonContainer5Click = useCallback(() => {
    // Please sync "Single News " to the project
  }, []);

  const onAboutUsHomeButtonClick = useCallback(() => {
    // Please sync "Company" to the project
    navigate("/company");
  }, []);

  const onOurProjectSectionButtonClick = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onAbousUsClick= useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);


  const getNews = async () =>{

    try {
  
      setLoading(true)
  
     // console.log('get coach bookmarks')
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;
  
  //console.log(currentUser)
       const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
    });
        const res = await client.get('/news')
        console.log('news',res.data)
        setNews(res.data)
  
        setLoading(false)
        
       
  
  
    } catch (error) {
        console.log(error)
    }
  };
   
  useEffect(() => {
  
   getNews()
  },[])

  const images = [
    { url: "/bodypicsinner@3x.png", text: "Pioneering Groundbreaking solutions in every blueprint" },
    { url: "/trucks.png", text: "Engineering Excellence, Delivering Impact: Solutions that Redefine Industries" },
    // { url: "image3.jpg", text: "Unleash your creativity" },
  ];
  



  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 9000); // Change content every 3 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);
 

  const newsSectionRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState("right");

  useEffect(() => {
    const scrollContainer = newsSectionRef.current;

    const scrollInterval = setInterval(() => {
      if (scrollContainer) {
        const maxScrollLeft =
          scrollContainer.scrollWidth - scrollContainer.clientWidth;

        if (scrollDirection === "right") {
          // Scroll to the right
          if (scrollContainer.scrollLeft >= maxScrollLeft) {
            setScrollDirection("left");
          } else {
            scrollContainer.scrollBy({ left: 300, behavior: "smooth" });
          }
        } else {
          // Scroll to the left
          if (scrollContainer.scrollLeft <= 0) {
            setScrollDirection("right");
          } else {
            scrollContainer.scrollBy({ left: -300, behavior: "smooth" });
          }
        }
      }
    }, 4000); // Adjust interval as needed

    return () => clearInterval(scrollInterval); // Cleanup interval on unmount
  }, [scrollDirection]);


  return (
    <>
      <div className="home">
      <NavBar/>
     
        <div className="bodypics">
          <div className="bodypicsinner"
  style={{
    backgroundImage: `url(${images[currentIndex].url})`, // Removed /public
  }}
>

            <div className="bodypic-writing">
              <b className="bodypic-writingtitle">Thinklab Group</b>
              <b className="bodypic-writingtext">
                  {images[currentIndex].text}
              </b>
              <div className="social-link-white">
              <a className="socialLogos" href="https://x.com/thinklabgroupng?s=11" target="_blank" rel="noopener noreferrer">
                <img
                  className="twitter-logo-icon"
                  alt=""
                  src="/twitter-logo.svg"
                />
                </a>
                {/* <img
                  className="twitter-logo-icon"
                  alt=""
                  src="/instagram-logosvg.svg"
                /> */}


<a className="socialLogos" href="https://www.instagram.com/thinklabgroupng?igsh=eXQ3NTV4bXU0czVt" target="_blank" rel="noopener noreferrer">
    <img src="/instagram-logosvg.svg" alt="Twitter Logo" class="twitter-logo-icon" />
</a>

<a className="socialLogos" href="https://www.facebook.com/Thinklabgroupng?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                <img
                  className="twitter-logo-icon"
                  alt=""
                  src="/facebook-symbolsvg.svg"
                />
                </a>

              </div>
            </div>
          </div>
        </div>

     


        <div className="ournewssection">
          <div className="ournewsheader">
            <b className="ournewsheadertext" style={{color: "white"}}>{`LATEST NEWS & INSIGHTS`}</b>
          </div>
          <div className="ournewssecsection" ref={newsSectionRef}
      style={{
        display: "flex",
        overflowX: "auto",
        scrollBehavior: "smooth",
      }}>

          {/* <div className="newscardhome">

              <div className="newscardhomeheader">
                <div className="newscardhomeheadertext">News</div>
              </div>
              <img
                className="newcardhometitleimg1-icon"
                alt=""
                src="/ecowas.jpg"
              />
              <div className="newcardhometitletext">
              Thinklab Group and Niger State Governement unveils massive development  projects for greater suleja in collaboration with Ecowas Bank.
              </div>

              <Link className='link' to={ `/news/${1}`}>
              <div
                className="newscardhomebutton"
                onClick={onNewsCardHomeButtonContainerClick}
              >
                <b className="newscardhomebuttontext">Read More</b>
              </div>
              </Link>
          </div> */}


          {news?.sort((a, b) => new Date(b.date) - new Date(a.date))  // Sort by latest date
          .map((newsItem, index) => (
            <Link className='link' to={`/news/${newsItem.id}`}>
    <div className="newscardhome" key={index} onClick={onNewsCardHomeButtonContainerClick}>
      <div className="newscardhomeheader">
        <div className="newscardhomeheadertext"><a className="newscardheadertitle"lass>News</a></div>
      </div>
      <img
        className="newcardhometitleimg1-icon"
        alt=""
        src={newsItem.firstImage.url}
      />

      <div>
        <div className="newCardDateBoxInner">
          <div className="newsCardIcon"></div>
          <a className="newsCardIconName">{new Date(newsItem.date).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
          </a>
        </div>
      </div>
      <div className="newcardhometitletext"><a className="newcardhometitletext2">
        {/* {newsItem.title} */}
        {newsItem.title.length > 80 ? `${newsItem.title.slice(0, 80)}...` : newsItem.title}
      </a></div>
      {/* <Link className='link' to={`/news/${newsItem.id}`}>
    
      
         <div
          className="newscardhomebutton"
          onClick={onNewsCardHomeButtonContainerClick}
        >
          <b className="newscardhomebuttontext">Read More</b>
        </div> 
      </Link> */}
    </div>
    </Link>
  ))}


          {/* <div className="newscardhome">
              <div className="newscardhomeheader">
                <div className="newscardhomeheadertext">News</div>
              </div>
              <img
                className="newcardhometitleimg1-icon"
                alt=""
                src="/ntp.jpg"
              />
              <div className="newcardhometitletext">
              Thinklab Group and Nigeria Institute of Town Planners Signs MOU on Joint Venture
              </div>
              <Link className='link' to={ `/news/${1}`}>
              <div
                className="newscardhomebutton"
                onClick={onNewsCardHomeButtonContainerClick}
              >
                <b className="newscardhomebuttontext">Read More</b>
              </div>
              </Link>
          </div>
          <div className="newscardhome">
              <div className="newscardhomeheader">
                <div className="newscardhomeheadertext">News</div>
              </div>
              <img
                className="newcardhometitleimg1-icon"
                alt=""
                src="/ubasani.jpg"
              />
              <div className="newcardhometitletext">
              Governor Uba Sani's Vision for People-Centric Infrastructure Takes Shape, The Giant Thinklab Group awarded the major road projects.
              </div>
              <Link className='link' to={ `/news/${1}`}>
              <div
                className="newscardhomebutton"
                onClick={onNewsCardHomeButtonContainerClick}
              >
                <b className="newscardhomebuttontext">Read More</b>
              </div>
              </Link>

          </div>


            <div className="newscardhome">
              <div className="newscardhomeheader">
                <div className="newscardhomeheadertext">News</div>
              </div>
              <img
                className="newcardhometitleimg1-icon"
                alt=""
                src="/newcardhometitleimg@2x.png"
              />
              <div className="newcardhometitletext">
                Thinklab Charity Foundation announces full tuition scholarship
                for top jamb scorers
              </div>
              <Link className='link' to={ `/news/${1}`}>
              <div
                className="newscardhomebutton"
                onClick={onNewsCardHomeButtonContainerClick}
              >
                <b className="newscardhomebuttontext">Read More</b>
              </div>
              </Link>
            </div>

            <div className="newscardhome">
              <div className="newscardhomeheader">
                <div className="newscardhomeheadertext">News</div>
              </div>
              <img
                className="newcardhometitleimg2-icon"
                alt=""
                src="/newcardhometitleimg1@2x.png"
              />
              <div className="newcardhometitletext1">
                <p className="niger-state-government">
                  Niger State Government Partners with Thinklab Group to Train
                  200 Youths in Bread Baking"
                </p>
              </div>
              <Link className='link' to={ `/news/${1}`}>
              <div
                className="newscardhomebutton"
                onClick={onNewsCardHomeButtonContainer2Click}
              >
                <b className="newscardhomebuttontext">Read More</b>
              </div>
              </Link>
            </div>
            <div className="newscardhome">
              <div className="newscardhomeheader">
                <div className="newscardhomeheadertext">Insight</div>
              </div>
              <img
                className="newcardhometitleimg2-icon"
                alt=""
                src="/newcardhometitleimg2@2x.png"
              />
              <div className="newcardhometitletext">
                Navigating City Development Challenges: Strategies for
                Sustainable Urban Growth
              </div>
              <div
                className="newscardhomebutton"
                onClick={onNewsCardHomeButtonContainer3Click}
              >
                <b className="newscardhomebuttontext">Read More</b>
              </div>
            </div>
            <div className="newscardhome">
              <div className="newscardhomeheader">
                <div className="newscardhomeheadertext">News</div>
              </div>
              <img
                className="newcardhometitleimg1-icon"
                alt=""
                src="/newcardhometitleimg3@2x.png"
              />
              <div className="newcardhometitletext">
                Thinklab Group Partners as Platinum Sponsor of 2024 Africa
                International Housing Show
              </div>
              <div
                className="newscardhomebutton"
                onClick={onNewsCardHomeButtonContainer4Click}
              >
                <b className="newscardhomebuttontext">Read More</b>
              </div>
            </div>
            <div className="newscardhome">
              <div className="newscardhomeheader">
                <div className="newscardhomeheadertext">News</div>
              </div>
              <img
                className="newcardhometitleimg-icon"
                alt=""
                src="/newcardhometitleimg4@2x.png"
              />
              <div className="newcardhometitletext4">
                Niger State Commissioner for Budget and Planning Commends
                ThinkLab Group Contractors for Their Hard Work
              </div>
              <div
                className="newscardhomebutton"
                onClick={onNewsCardHomeButtonContainer5Click}
              >
                <b className="newscardhomebuttontext">Read More</b>
              </div>
            </div>
 */}

          </div>
        </div>
        <div className="ourcompanysection">
        <div className="ournewsheader">
            <b className="ournewsheadertext">{`OUR COMPANY`}</b>
          </div>


          
          <div className="aboutushomesection">
            <div className="aboutushometext">
              <div className="text-group-thinklab">
              <div className="ournewsheader">
            <b className="ournewsheadertext">{`ABOUT US`}</b>
          </div>
                <div className="paragraph-block-text">
                  <div className="thinklab-group-is-container">
                    <p className="niger-state-government">
                      Thinklab Group is a conglomerate comprised of Thinklab
                      Development Company Limited, Thinklab Energy Limited,
                      Thinklab International Consulting Limited, and Thinklab
                      Digital Technology Limited. As a trusted and reputable
                      provider, Thinklab Group delivers reliable, innovative,
                      and efficient solutions for a wide range of construction
                      projects. With expertise in City Development, building
                      construction, industrial facilities, civil structures,
                      roads, and infrastructure, we consistently achieve
                      exceptional results that meet the highest industry
                      standards.
                    </p>
                  </div>
                </div>
              </div>
              <button
                className="aboutushomebutton"
                onClick={onAboutUsHomeButtonClick}
              >
                <b className="aboutushomebuttontext">Read More</b>
              </button>
            </div>
            <img
              className="aboutushomeimg-icon"
              alt=""
              src="/aboutushomeimg@2x.png"
            />
          </div>
        </div>
        <div className="ourculturesection">
          <div className="ourculturetextgroup">
          <div className="ournewsheader">
            <b className="ournewsheadertext">{`OUR  CULTURE`}</b>
          </div>
            <div className="paragraph-block-text1">
              <div className="our-culture-is">
                Our culture is a vibrant tapestry woven from diverse threads:
                the dedication of our people, the relentless pursuit of
                innovation, and the unwavering commitment to making a positive
                impact. Together, we build not just structures, but legacies.
              </div>
            </div>
          </div>
          <div className="ourculturemainslider">
            <div className="our-culture-navigtion-link">
              {/* <div className="our-culture-linkselected">
                <b className="ourculturelinkselectedtext">ESG Strategy</b>
              </div> */}

              <div className="our-culture-link">
                <div className="thinklab-group-is-container"><a style={{color:"white"}}>ESG Strategy</a></div>
              </div>
              <div className="our-culture-link">
                <div className="thinklab-group-is-container"><a style={{color:"white"}}>Community</a></div>
              </div>
              <div className="our-culture-link">
                <div className="thinklab-group-is-container"><a style={{color:"white"}}>{`DE&I`}</a></div>
              </div>
              <div className="our-culture-link">
                <div className="thinklab-group-is-container"><a style={{color:"white"}}>Environmental</a></div>
              </div>
              <div className="our-culture-link">
                <div className="thinklab-group-is-container"><a style={{color:"white"}}>Innovation</a></div>
              </div>
              <div className="our-culture-link">
                <div className="thinklab-group-is-container"><a style={{color:"white"}}>{`Safety & Wellness`}</a></div>
              </div>
            </div>
            <div className="our-culture-media-sliderbody2">
              <div className="ourcultureslidertextgroup">
                <div className="headingblockourcultureslider">
                  <b className="headingblockourculturesliderte">{`Environmental, Social, & Governance (ESG) Strategy`}</b>
                </div>
                <div className="textblockourcultureslider">
                  We weave sustainability and ethical practices into everything
                  we do, minimizing our environmental impact and building a
                  better future
                </div>
                <div className="ourculturebutton">
                  <b className="newscardhomebuttontext">Learn More</b>
                </div>
              </div>
              <img
                className="ourcultureimg-icon"
                alt=""
                src="/ourcultureimg@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="ourprojectsection">
          <div className="ourculturetextgroup">
          <div className="ournewsheader">
            <b className="ournewsheadertext">{`OUR PROJECT`}</b>
          </div>
            <div className="paragraph-block-text">
              <div className="building-brighter-futures">
                Building Brighter Futures: A Glimpse into Our Portfolio
              </div>
            </div>
          </div>
          <div className="our-projectbody">
            <div className="our-projectsectionone">
              <div className="our-projectsectiononeleft">
                <img
                  className="our-projectsectiononeleftinner-icon"
                  alt=""
                  src="/our-projectsectiononeleftinner@2x.png"
                />
              </div>
              <div className="our-projectsectiononeright">
                <b className="our-projectsectionone-righttit">
                  Construction of Mass Housing Infrastructure
                </b>
                <div className="our-projectsectiononerighour-p">
                  The Construction of Mass Housing Infrastructure project is a
                  comprehensive endeavor aimed at developing essential
                  infrastructure to support large-scale housing projects in
                  urban and peri-urban areas. This project encompasses a range
                  of infrastructure components vital for creating sustainable
                  and livable communities, with a focus on enhancing
                  connectivity, utilities, and amenities for residents.
                </div>
              </div>
            </div>
            <div className="our-projectsectionone">
              <div className="our-projectsectiononeright">
                <b className="our-projectsectiontwotitle">
                  Niger State IBBUTH Project
                </b>
                <div className="our-projectsectiononerighour-p">
                  The Niger State Teaching Hospital Project is a visionary
                  initiative aimed at revolutionizing healthcare and improving
                  the well-being of residents in Niger State, Nigeria. This
                  project is a collaborative effort between the government,
                  private sector stakeholders, and development partners, with a
                  focus on providing accessible, affordable, and sustainable
                  healthcare solutions to the population. By integrating
                  cutting-edge medical technology, innovative teaching methods,
                  and community engagement, we aim to create a hub for
                  excellence in healthcare delivery, medical education, and
                  research, ultimately enhancing the health and quality of life
                  for our patients and the community we serve.
                </div>
              </div>
              <div className="our-projectsectiononeleft">
                <img
                  className="our-projectsectiononeleftinner-icon"
                  alt=""
                  src="/our-projectsectiontwoimg@2x.png"
                />
              </div>
            </div>
          </div>
          <button
            className="aboutushomebutton"
            onClick={onOurProjectSectionButtonClick}
          >
            <b className="aboutushomebuttontext">View All Projects</b>
          </button>
        </div>
        <div className="ourcompanysection">
        <div className="ournewsheader">
            <b className="ournewsheadertext">{`JOIN THE TEAM`}</b>
          </div>
          <div className="aboutushomesection">
            <div className="aboutushometext">
              <div className="text-group-thinklab">
              <div className="ournewsheader">
            <b className="ournewsheadertext">{`ABOUT US`}</b>
          </div>
                <div className="paragraph-block-text">
                  <div className="thinklab-group-is-container">
                    At Thinklab Group, we believe in the power of people and
                    ideas to shape a better future. We offer a dynamic and
                    supportive environment where you can grow your skills,
                    collaborate with passionate colleagues, and make a lasting
                    impact on the world around you. Join us and be part of
                    something extraordinary.
                  </div>
                </div>
              </div>
              <div className="ourculturebutton">
                <b className="careerteambuttontext">
                  Explore A Career With Thinklab
                </b>
              </div>
            </div>
            <img
              className="aboutushomeimg-icon"
              alt=""
              src="/joinourteamimg@2x.png"
            />
          </div>
        </div>
        <div className="testimonial-section-thinklab">
          <div className="text-group-thinklab1">
            <div className="heading-block-text4">
              <b className="heading-block-text8">Testimonials</b>
            </div>
            <div className="paragraph-block-text">
              <div className="building-brighter-futures">
                Explore the impact of Thinklab Group through the voices of
                satisfied clients. Hear what our clients say about real
                experiences and real results!
              </div>
            </div>
          </div>
          <div className="testimonial-cards-container">
            <div className="testimonial-card">
              <div className="avatar-container-parent">
                <img
                  className="avatar-container-icon"
                  alt=""
                  src="/avatar-container@2x.png"
                />
                <div className="we-are-extremely">
                  We are extremely pleased with the exceptional work delivered
                  by Thinklab Group in Yobe State. Their team's expertise,
                  efficiency, and adherence to timelines have been commendable.
                  We look forward to continued collaboration with them on future
                  projects.
                </div>
                <div className="hon-mai-mala-buni-parent">
                  <div className="hon-mai-mala">Hon. Mai Mala Buni</div>
                  <div className="governor-yobe-state">
                    Governor, Yobe State
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-card1">
              <img
                className="avatar-container-icon"
                alt=""
                src="/avatar-container1@2x.png"
              />
              <div className="we-are-extremely">
                I confidently recommend Thinklab Group Limited for any
                infrastructure project. Their track record of success, coupled
                with their commitment to meeting client needs, makes them a
                standout choice for construction endeavors in Niger State.
              </div>
              <div className="hon-mai-mala-buni-parent">
                <div className="hon-mai-mala">Hon. Muhammad Umar Bago</div>
                <div className="governor-yobe-state">
                  Farmer Governor, Niger State
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-cards-container1">
            <div className="testimonial-card2">
              <img
                className="avatar-container-icon"
                alt=""
                src="/avatar-container2@2x.png"
              />
              <div className="we-are-extremely">
                I confidently recommend Thinklab Group Limited for any
                infrastructure project. Their track record of success, coupled
                with their commitment to meeting client needs, makes them a
                standout choice for construction endeavors in Niger State.
              </div>
              <div className="hon-mai-mala-buni-parent">
                <div className="hon-mai-mala">Hon. Muhammad Umar Bago</div>
                <div className="governor-yobe-state">
                  Farmer Governor, Niger State
                </div>
              </div>
            </div>
            <div className="testimonial-card3">
              <img
                className="avatar-container-icon"
                alt=""
                src="/avatar-container@2x.png"
              />
              <div className="secondary-text-link">
                <div className="building-brighter-futures">
                  We are extremely pleased with the exceptional work delivered
                  by Thinklab Group in Yobe State. Their team's expertise,
                  efficiency, and adherence to timelines have been commendable.
                  We look forward to continued collaboration with them on future
                  projects.
                </div>
              </div>
              <div className="namepositionavatar">
                <div className="text-group-thinklab2">
                  <div className="heading-block-text">
                    <b className="hon-mai-mala">Hon. Mai Mala Buni</b>
                  </div>
                  <div className="paragraph-block-text5">
                    <div className="lorem-ipsum-dolor2">
                      Governor, Yobe State
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us-section-thinklab">
          <div className="text-group-thinklab3">
          <div className="ournewsheader">
            <b className="ournewsheadertext">{`CONTACT US`}</b>
          </div>
            <div className="paragraph-block-text">
              <div className="building-brighter-futures">
                We appreciate your interest in Thinklab Group. Whether you're
                seeking assistance or have a general inquiry, we're eager to
                engage. Send us a message, and you can expect a prompt response.
              </div>
            </div>
          </div>
          <div className="contact-us-lower-container">
            <div className="contact-form-thinklab">
              <div className="upper-contact-form-section">
              <div className="ournewsheader">
            <b className="ournewsheadertext">{`CONTACT FORM`}</b>
          </div>
                <div className="input-groups-container-thinkla">
                  <div className="input-group-single-thinklab">
                    <div className="heading-block-text">
                      <div className="lorem-ipsum-dolor2">Name</div>
                    </div>
                    <input
                      className="input-field-single"
                      placeholder="Enter Your Full Name"
                      type="text"
                    />
                  </div>
                  <div className="input-group-single-thinklab">
                    <div className="heading-block-text">
                      <div className="lorem-ipsum-dolor2">Email</div>
                    </div>
                    <input
                      className="input-field-single"
                      placeholder="Enter Your Email Address"
                      type="text"
                    />
                  </div>
                  <div className="input-group-single-thinklab">
                    <div className="heading-block-text">
                      <div className="lorem-ipsum-dolor2">Message</div>
                    </div>
                    <textarea
                      className="input-field-multi-line"
                      placeholder="Enter Your Message"
                    />
                  </div>
                </div>
              </div>
              <button className="primary-button">
                <b className="aboutushomebuttontext">Send Message</b>
              </button>
            </div>
            <div className="contact-address-card">
            <div className="ournewsheader">
            <b className="ournewsheadertext">{`CONTACT ADDRESS`}</b>
          </div>
              <div className="contat-info-buttom-section">
                <div className="address-info-container">
                  <img
                    className="address-icon"
                    alt=""
                    src="/address-icon.svg"
                  />
                  <div className="secondary-text-link">
                    <div className="building-brighter-futures">
                      87 Samuel Ladoke Akintola Boulevard, Garki 2, Abuja
                    </div>
                  </div>
                </div>
                <div className="call-info-container">
                  <img className="address-icon" alt="" src="/call-icon.svg" />
                  <div className="secondary-text-link">
                    <div className="building-brighter-futures">
                      +234 9084359943
                    </div>
                  </div>
                </div>
                <div className="address-info-container">
                  <img className="mail-icon" alt="" src="/mail-icon.svg" />
                  <div className="secondary-text-link">
                    <div className="building-brighter-futures">
                      Project@thinklabgroup.com.ng
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-section-thinklab">
          <div className="footer-upper-container">
            <div className="logotaglinesocial-links-cont">
              <div className="footer-logo-and-tagline-contai">
                <img
                  className="thinklab-logo-icon1"
                  alt=""
                  src="/thinklab-logo@2x.png"
                />
                <div className="paragraph-block-text7">
                  <div className="building-brighter-futures">
                    <p className="niger-state-government">
                      Crafting Excellence in every blueprint.
                    </p>
                    <p className="niger-state-government">
                      Trust us to redefine standards and deliver unmatched
                      efficiency across diverse domains.
                    </p>
                  </div>
                </div>
              </div>
              <div className="social-link-white">
                <img
                  className="copyright-icon"
                  alt=""
                  src="/twitter-logo1.svg"
                />
                <img
                  className="copyright-icon"
                  alt=""
                  src="/instagram-logosvg1.svg"
                />
                <img
                  className="copyright-icon"
                  alt=""
                  src="/facebook-symbolsvg1.svg"
                />
              </div>
            </div>
            <div className="footer-navigation-link">
              <div className="heading-block-text">
                <div className="lorem-ipsum-dolor2">Our Company</div>
              </div>
              <div className="heading-block-text">
                <div className="lorem-ipsum-dolor2">Our Services</div>
              </div>
              <div className="heading-block-text">
                <div className="lorem-ipsum-dolor2">Our Projects</div>
              </div>
              <div className="heading-block-text">
                <div className="lorem-ipsum-dolor2">{`News & Insights`}</div>
              </div>
              <div className="heading-block-text">
                <div className="lorem-ipsum-dolor2">Careers</div>
              </div>
              <div className="heading-block-text">
                <div className="lorem-ipsum-dolor2">Contact Us</div>
              </div>
            </div>
          </div>
          <div className="all-right-reserved">
            <div className="copyright-parent">
              <img className="copyright-icon" alt="" src="/copyright.svg" />
              <div className="heading-block-text">
                <div className="div">2024</div>
              </div>
            </div>
            <div className="div">{`Thinklab Group. All Rights Reserved. `}</div>
          </div>
        </div>
      </div>
      {/* {isTabletmobileOverlayMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTabletmobileOverlayMenu}
        >
          <TabletmobileOverlayMenu1 onClose={closeTabletmobileOverlayMenu} />
        </PortalPopup>
      )} */}
    </>
  );
};

export default Home;
