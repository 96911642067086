import axios from "axios";
import { useSelector } from "react-redux";

const BASE_URL = "https://thinklab-2d7593cab5e8.herokuapp.com/v1/";
//mnhconst BASE_URL = "https://scholist-app-878d1bd3ab8e.herokuapp.com/v1/";

export const BASE_Server_URL = "https://thinklab-2d7593cab5e8.herokuapp.com/v1/";

//export const BASE_Server_URL = "https://scholist-app-878d1bd3ab8e.herokuapp.com/v1/";

// export let Server_URL = "http://localhost:3001/";

//huhexport let Server_URL = "https://hammerhead-app-lwq9o.ondigitalocean.app/";

// const TOKEN =
//   JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user).currentUser
//     .accessToken || "";

//const cuser = useSelector(state => state.user.currentUser)
const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
 const currentUser = user && JSON.parse(user).currentUser;
const TOKEN = currentUser?.tokens.access.token;


//console.log('user',user)

//console.log(TOKEN)


export const publicRequest = axios.create({
    baseURL: BASE_URL,
  });
 // console.log(BASE_URL)
  //console.log(TOKEN)
  
  export const userRequest = axios.create({
    baseURL: BASE_URL,
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
